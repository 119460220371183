import React from "react"
import Layout from '../../components/layout'
import SEO from '../../components/seo'
// import { Container } from '../../components/global'
import styled from 'styled-components'

import PrivacyPolicy from './privacy_policy/zyppd-in-privacy-policy.pdf'
import pp0 from './privacy_policy/pp-0.png'
import pp1 from './privacy_policy/pp-1.png'
import pp2 from './privacy_policy/pp-2.png'
import pp3 from './privacy_policy/pp-3.png'

export default function TermsAndConditions() {

    return (
        <Layout>
            <SEO
                title="Privacy Policy"
            />
            <Container>
                <PDFLink href={PrivacyPolicy} download>Download the zyppd.in privacy policy</PDFLink>
                <LegalImgs>
                    <img src={pp3} alt="" srcset="" />
                    <img src={pp2} alt="" srcset="" />
                    <img src={pp1} alt="" srcset="" />
                    <img src={pp0} alt="" srcset="" />
                </LegalImgs>
            </Container>
        </Layout>
    )
}

const Container = styled.div`
    max-width: 900px;
    margin: 0 auto;
`
const PDFLink = styled.a`
    text-decoration: underline;
    text-align: center;
    margin: 2em 0;
    display: inline-block;
    width: 100%;
`

const LegalImgs = styled.div`
    display: grid;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    img {
        margin: 0 1em 1em 0;
        max-width: 550px;
        width: 100%;
        height: 100%; 
    }
`